import React from 'react';

import Main from 'layouts/Main';
import {
  Hero,
} from './components';

const About = () => {
  return (
    <Main colorInvert={true}>
      <Hero />

    </Main>
  );
};

export default About;
