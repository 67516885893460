const pages = {
  products: [
    {
      title: 'Cyber Threat Intelligence',
      href: '/products',
    },
  ],
  company: [
    {
      title: 'Overview',
      href: '/about',
    },
  ],
  casestudies: [
    {
      title: 'Master Data Management',
      href: '/mdm-casestudy',
    },
    {
      title: 'Open Banking',
      href: '/openbanking-casestudy',
    },
    {
      title: 'Cyber Threat Management',
      href: '/cti-casestudy',
    },
  ],
  services: [
    {
      title: 'Technology Delivery',
      href: '/technology-delivery-services',
    },
    {
      title: 'Architecture Delivery',
      href: '/archtecture-delivery-services',
    },
    {
      title: 'Cloud Computing',
      href: '/cloud-computing-services',
    },
    {
      title: 'Master Data Management',
      href: '/mdm-services',
    },
  ],
  developer: [
    {
      title: 'Developer Portal',
      href: '/dev-portal',
    },
  ],
};

export default pages;
