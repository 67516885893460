import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

import NavItem from './components/NavItem';
import logoImage2 from '../../../../../../images/image2.png';
import logoImage1 from '../../../../../../images/image1.png';

const SidebarNav = ({ pages }) => {
  const theme = useTheme();
  const { mode } = theme.palette;

  const {
    products: productPages,
    services: servicePages,
    developer: developerPages,
    casestudies: casestudyPages,
    company: companyPages,
  } = pages;

  return (
    <Box>
      <Box width={1} paddingX={2} paddingY={1}>
        <Box
          display={'flex'}
          component="a"
          href="/"
          title="gsslAI"
          width={{ xs: 100, md: 120 }}
        >
          <Box
            component={'img'}
            src={mode === 'light' ? logoImage1 : logoImage2}
            height={1}
            width={1}
          />
        </Box>
      </Box>
      <Box paddingX={2} paddingY={2}>
        <Box>
          <NavItem title={'Products'} items={productPages} />
        </Box>
        <Box>
          <NavItem title={'Services'} items={servicePages} />
        </Box>
        <Box>
          <NavItem title={'Developers'} items={developerPages} />
        </Box>
        <Box>
          <NavItem title={'Case Stuides'} items={casestudyPages} />
        </Box>
        <Box>
          <NavItem title={'Company'} items={companyPages} />
        </Box>
      </Box>
    </Box>
  );
};

SidebarNav.propTypes = {
  pages: PropTypes.object.isRequired,
};

export default SidebarNav;
