import React from 'react';

import {
  IndexView,
  Home as HomeView,
  About as About,
  NotFound as NotFoundView,
  NotFoundCover as NotFoundCoverView,
} from 'views';
import Products from './Products/Products';

const routes = [
  {
    path: '/',
    renderer: (params = {}) => <IndexView {...params} />,
  },
  {
    path: '/home',
    renderer: (params = {}) => <HomeView {...params} />,
  },
  {
    path: '/about',
    renderer: (params = {}) => <About {...params} />,
  },
  {
    path: '/products',
    renderer: (params = {}) => <Products {...params} />,
  },
  {
    path: '/not-found',
    renderer: (params = {}) => <NotFoundView {...params} />,
  },
  {
    path: '/not-found-cover',
    renderer: (params = {}) => <NotFoundCoverView {...params} />,
  },
];

export default routes;
