import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import logoImage2 from '../../../../images/image2.png';
import logoImage1 from '../../../../images/image1.png';
import cyberEssentials from '../../../../images/cyberessentialslogo.png';
import ccsLogo from '../../../../images/CCS_2935_Supplier_AW_300dpi.jpg';

const Footer = () => {
  const theme = useTheme();
  const { mode } = theme.palette;

  return (
    <Grid container spacing={1}>
      <Grid item xs={8}>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          width={1}
          flexDirection={{ xs: 'column', sm: 'row' }}
        >
          <Box
            display={'flex'}
            component="a"
            href="/"
            title="GSSL AI"
            width={100}
          >
            <Box
              component={'img'}
              src={mode === 'light' ? logoImage1 : logoImage2}
              height={1}
              width={1}
            />
          </Box>
          <Box
            display={'flex'}
            component="a"
            href="/"
            title="GSSL AI"
            width={70}
          >

          </Box>
          <Box
            display={'flex'}
            component="a"
            href="/"
            title="GSSL AI"
            width={80}
          >
            <Box
              component={'img'}
              src={mode === 'light' ? ccsLogo : ccsLogo}
              height={1}
              width={1}
              alignItems={'left'}
            /><Box
              component={'img'}
              src={mode === 'light' ? cyberEssentials : cyberEssentials}
              height={1}
              width={1}
              alignItems={'left'}
            />
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Typography
          align={'center'}
          variant={'subtitle2'}
          color="text.secondary"
          gutterBottom
        >
          &copy; 2023 GSSL AI All rights reserved
        </Typography>
        <Typography
          align={'center'}
          variant={'caption'}
          color="text.secondary"
          component={'p'}
        >
          When you visit or interact with our sites, services or tools, we or
          our authorised service providers may use cookies for storing
          information to help provide you with a better, faster and safer
          experience and for marketing purposes.
        </Typography>
      </Grid>
    </Grid >
  );
};

export default Footer;
