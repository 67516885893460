import React from 'react';

import Main from 'layouts/Main';
import {
  Cti,
} from './components';

const Products = () => {
  return (
    <Main colorInvert={true}>
      <Cti />

    </Main>
  );
};

export default Products;
